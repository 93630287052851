import React from 'react'

import Seo from '../components/seo'
import Layout from '../components/layout'

class OemIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo title="Oem" />
        <div align="center">
            <a>オリジナル製品つくります！<br/>
サロン品質の化粧品製造発注を承っております。<br/>
小ロットから、高品質、特徴ある商品をお作りいたします。<br/>
        輸出をお考えのお客様も対応可能ですので、お問い合わせください。</a><br/>
        </div>
        <div align="center">
            We make original products! <br/>We accept orders for the production of salon-quality cosmetics. <br/>We can produce high quality, distinctive products in small lots. <br/>We can also handle customers who are considering exporting their products.
        </div>
      </Layout>
    )
  }
}

export default OemIndex

